<template>
    <div class="group-page-box"  >
        <template v-if="showSettingPage" >
            <GroupSettingPage :fullHeight="fullHeight" />
        </template>
        <template v-else >
            <div class="group-page clearfix">
                <div class="group-left-menu">
                    <div class="group-left-menu-header">
                        <GroupMenuHeader :checkGroupData="checkGroupData" :setGroupData="setGroupData" @searchGroup="searchGroup" />
                    </div>
                    <div v-if="myGroupData.length > 0" :style="{height: fullHeight - 142 + 'px'}" id="leftGroupListBox" class="group-left-menu-group-box">
                        <GroupMenuBox :showDataType="showDataType"
                        :checkGroupData="checkGroupData"
                        :myGroupData="myGroupData" 
                        :checkGroupDataDetail="checkGroupDataDetail" 
                        :searchGroupData="searchGroupData"  
                        @clickSetting="clickSetting"
                        @checkGroup="checkGroup" 
                        @checkGroupFile="checkGroupFile" />
                    </div>
                    <div v-else :style="{height: fullHeight - 142 + 'px',textAlign: 'center',lineHeight: fullHeight - 362 + 'px'}" id="leftGroupListBox" class="group-left-menu-group-box">
                        {{ $getStringObj.getString($Strings.Not_Joined_Any_Group) }}~
                        <!-- 你还没有加入任何共享组 -->
                    </div>
                </div>
                <div class="group-right-content-father">
                    <div  v-if="myGroupData.length > 0"  class="group-right-content">
                        <div class="group-detail">
                            <GroupDetail :fullHeight="fullHeight" :fullWidth="fullWidth" :checkGroupData="checkGroupData" :checkGroupDataDetail="checkGroupDataDetail" />
                        </div>
                        <div class="message-board">
                            <GroupMessageBoard :fullHeight="fullHeight" />
                        </div>
                    </div>
                    <div v-else class="group-right-content">
                        <div class="message-board">
                            <NotToJoinTheGroup :fullHeight="fullHeight" />
                        </div>
                    </div>
                </div>

            </div>
            <!-- <template v-else>
                
            </template> -->
        </template>
    </div>
</template>

<script>
import GroupMenuHeader from "../groupManagementChildren/GroupMenuHeader"
import GroupMenuBox from "../groupManagementChildren/GroupMenuBox"
import GroupDetail from "../groupManagementChildren/GroupDetail"
import GroupMessageBoard from "../groupManagementChildren/GroupMessageBoard"
import NotToJoinTheGroup from "../groupManagementChildren/NotToJoinTheGroup"
import GroupSettingPage from "../groupManagementChildren/groupSettingPage/GroupSettingPage"
import { postGroupSearchMyGroups, postGroupProfile,  } from "../../common/netWork/group_api";
import {postGroupMyGroups} from '../../common/netWork/networkcache';
import { mapMutations } from "vuex";

export default {
    components: {
        GroupMenuHeader,
        GroupMenuBox,
        GroupDetail,
        GroupMessageBoard,
        NotToJoinTheGroup,
        GroupSettingPage,
    },
    data() {
        return {
            myGroupData: [],//我的共享组
            searchGroupData: [], //搜索的共享组数据
            showDataType: 'myGroup', //当前群组列表的类型，如果是'myGroup'代表我的群组，如果是'searchMyGroup'代表的是搜索的群组
            checkGroupData: null, //当前选中的共享组的数据。
            checkGroupDataDetail: null, //当前选中共享组的详情数据
            showSettingPage: false,
            settingshow: false, //是否展示设置页面
            fullHeight: document.documentElement.clientHeight, //中间内容部分的高度
            fullWidth: document.documentElement.clientWidth,
            setGroupData:null,
            isScroll: true, //当前是否可以滚动加载数据
            page: 1, //当前群组列表的页数
        }
    },
    created() {
        this.refreshList();
        this.get_bodyWidth();
    },
    methods: {
        ...mapMutations([
            "changeGroupDataDetail",
            "setGroupDataDetail"
        ]),

        get_bodyWidth() {
            //动态获取浏览器高度
            const that = this;
            window.addEventListener("resize", () => {
                that.fullHeight = document.documentElement.clientHeight;
                that.fullWidth = document.documentElement.clientWidth;
                // that.bus.$emit("fullWidth", that.fullWidth);
            });
        },

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("leftGroupListBox");
            let child = document.getElementById("leftGroupList");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        refreshList() { //刷新我的共享组
            
            this.page = 1;
            postGroupMyGroups({page: this.page},res => {
                this.showDataType = 'myGroup'
                //如果群组有数据就先展示第一个数据。
                if(res != null && res.myGroups != null && res.myGroups.length > 0){
                    this.myGroupData = res.myGroups ;
                    this.setGroupData = res;
                    if (this.checkGroupData != null) {
                        let len = this.myGroupData.length
                        for (let index = 0; index < len; index++) {
                            //判断请求回来的组数据有没有之前选中的组。
                            if (this.checkGroupData.id == this.myGroupData[index].id) {   
                                 this.checkGroup(this.myGroupData[index]);
                                break;
                            } 
                            if (index == len -1) {
                                this.checkGroup(this.myGroupData[0]);
                            }
                        }
                    } else {
                        this.checkGroup(this.myGroupData[0]);
                    }
                } else {
                    this.myGroupData = [] 
                }

                this.$nextTick(() => {
                    let scrollDom = document.getElementById("leftGroupListBox");
                    if (scrollDom != undefined) {
                        scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                        scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                    }
                });
            })
        },

        scrollPullList() {
            let obj = { page: this.page + 1 };
            postGroupMyGroups(obj,res => {
                if (res !=  null && res.myGroups != null && res.myGroups.length > 0) {
                  this.page++;
                  this.myGroupData = this.myGroupData.concat(res.myGroups);
                }

            },null,res=> {
                this.isScroll = true;
            })
        },

        clickSetting(val) {
            this.checkGroup(val.item)
            this.settingshow = true;
        },

        checkGroup(item) {
            this.checkGroupData = item;
            if (this.checkGroupData == null) {
                return;
            }
            this.pullMyGroupDetail({id: item.id}).then((res) => {
                this.changeGroupDataDetail(res);
                this.checkGroupDataDetail = res;
                if(this.settingshow) {
                    this.setGroupDataDetail(this.checkGroupDataDetail);
                    this.settingshow = false;
                }
            })
            this.bus.$emit("showFilePage", false);

        },
        checkGroupFile(item){
             this.checkGroupData = item;
            if (this.checkGroupData == null) {
                return;
            }
            this.pullMyGroupDetail({id: item.id}).then((res) => {
                this.changeGroupDataDetail(res);
                this.checkGroupDataDetail = res;
                if(this.settingshow) {
                    this.setGroupDataDetail(this.checkGroupDataDetail);
                    this.settingshow = false;
                }
            })
            this.showSettingPage = false;
            this.bus.$emit("showFilePage", true);
        },

        searchGroup(obj) {
            if (obj.text == '') {
                this.refreshList()
            } else {
                this.refreshSearchList({'query': obj.text});
            }
        },

        refreshSearchList(obj) {
            this.pullSearchMyGroup(obj).then(res => {
                this.searchGroupData = res.mygroups
                this.showDataType = 'searchMyGroup'
                if (this.searchGroupData.length > 0) {
                    this.checkGroup(this.searchGroupData[0]);
                }
            });
        },

        pullMyGroupDetail(obj) {
            return new Promise((resolve, reject) => {
                postGroupProfile(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullSearchMyGroup(obj) {
            return new Promise((resolve, reject) => {
                postGroupSearchMyGroups(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
    },
    watch: {
        '$store.state.refreshMyGroupList' : {
            handler(newVal,oldVal) {
                this.refreshList();
            },
        },
        '$store.state.showGroupSetting' : {
            handler(newVal,oldVal) {
                this.showSettingPage = newVal;
            },
            immediate: true,
        }
    }

}
</script>

<style lang="less">
    .group-page-box {
        margin: 0 auto;
        background-color: #F5F5F5;
        background-position: center bottom;
        background-size: cover;
        .group-page-box-center {
            margin: 0 auto;
        }
        .group-left-menu {
            // float: left;
            border-right: 1px solid #dadada;
            background-color: #fdfdfd;// #ECE8E8;
            .group-left-menu-group-box {
                overflow-y: auto;
                 //滚动条美化
                &::-webkit-scrollbar {
                    width: 4px;
                }
                &::-webkit-scrollbar-track {
                    background: rgb(239, 239, 239);
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #bfbfbf;
                }
            }
        }

        .group-right-content-father {
            // float:left;
            padding-left: 443px;
        }
        
        .group-page {
            width: 1200px;
            max-width: 1200px;
            margin: 0 auto;
            .group-left-menu {
                float: left;
                width: 443px;
            }
            .group-right-content {
                // padding-left: 443px;
                width: 100%;
                overflow-y: auto;
                //滚动条美化
                &::-webkit-scrollbar {
                    width: 6px;
                }
                &::-webkit-scrollbar-track {
                    background: rgb(239, 239, 239);
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #bfbfbf;
                }
            }
        }

        //     .null-group-img {
        //         display: block;
        //         margin: 0 auto;
        //         margin-top: 226px;
        //     }
        //     .null-group-text-title {
        //         margin: 0 auto;
        //         margin-top: 12px;
        //         text-align: center;
        //     }
        //     .null-group-botton-box {
        //         margin: 0 auto;
        //         margin-top: 48px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         .group-button {
        //             display: flex;
        //             margin: 0 20px;
        //             padding: 5px 10px;
        //             border-radius: 20px;
        //             align-items: center;
        //             justify-content: center;
        //             font-size: 20px;
        //             background-color: red;
        //             color: #fff;
        //             img {
        //                 width: 20px;
        //                 height: 20px;
        //             }
        //         }
        //         .group-button:hover {
        //             cursor: pointer;
        //         }
        //         .group-button:active {
        //             position: relative;
        //             left: 1px;
        //             top: 1px;
        //         }

        //         .group-add-button {
        //             background-color: #3D71F2;
        //         }

        //         .group-created-button {
        //             background-color: #FD492B;
        //         }
        //     }

        // }
    }

</style>