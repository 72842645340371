<template>
      <div class="move-father-box" @click="hideMoveFile(false)" v-show="moveFile">
          <div @click.stop="hideMoveFile(true)" class="move-child-box">
              <div class="move-title">
                  <div class="setting-title">
                    {{ getString(strings.Mind_Archive) }}
                  </div>
                  <div class="close-setting">
                      <img class="close-button-img" src="../../../../../assets/img/popupsImg/colse_new.svg"  @click.stop="hideMoveFile(false,2)" />
                  </div>
              </div>
              <div class="move-header">
                <GroupMindCrumbs @backToThePreviousLevel="backToThePreviousLevel" :showPath="false" :mindFileObj="mindFileObj" />
              </div>
                    <div id="movefilepagebox" class="mind-file-list-box">
                      <div id="movefilepageboxchild">
                        <template v-if="gfilesArr != null && moveItem != null  && gfilesArr.length>0">
                          <div v-for="(item,index) in gfilesArr" :key="index" @click="openMind(item)" class="clearfix">
                            <template v-if="item.type == 0 && item.id != moveItem.id">
                              <div class="mind-file-list-box-each clearfix">
                                <div class="mind-each-center">
                                  <div class="mind-each-center-title">
                                    <div class="mind-each-center-title-content">
                                      <div class="title-content-name">
                                        {{ item.name }}
                                      </div>
                                      <div v-if="item.isTop" class="mind-each-center-title-stick">
                                        置顶1
                                      </div>
                                    </div>
                                    <div class="mind-each-center-title-sub-content">
                                      {{ item.subtitle }}
                                    </div>
                                  </div>
                                </div>
                                <img class="mind-each-left" v-if="item.type == 0" src="../../../../../assets/img/groupImg/file-icon.svg" alt="">
                                <img class="mind-each-left" v-if="item.type == 1" src="../../../../../assets/img/user/plate_plan_4.jpg"
                                    v-real-img="httpImageToPrefix(item.cover)" alt="">
                                
                                <div class="mind-each-right">
                                  <div class="date-detail">
                                    {{ timestampToTimeDHM(item.createTime) }}
                                  </div>
                                  <!-- <div @mouseover="movEachMenu($event,true,item)" @mouseout="movEachMenu($event,false,item)" class="mind-file-menu-icon">
                                    <img src="../../../../../assets/img/groupImg/mind-file-menu-icon.png" alt="">
                                  </div> -->
                                  
                                </div>
                              </div>
                              <div v-if="index != gfilesArr.length - 1" class="each-box-bottom-line" ></div>
                            </template>
                          </div>
                        </template>
                        <div class="current-location" v-else>
                          <img src="../../../../../assets/img/arvhive/currentLocation.svg" width="88px" alt="">
                        </div>
                      </div>
                    </div>

              <div class="move-footer">
                  <div @click.stop="hideMoveFile(false,2)" class="button-class button-class-colse">
                      {{getString(strings.Global_Cancel)}}
                  </div>
                  <div @click.stop="hideMoveFile(false,1)" class="button-class button-class-ok">
                      {{getString(strings.Global_Ok)}}
                  </div>
                <!-- <button @click.stop="hideMoveFile(false,1)">确定</button> <button  @click.stop="hideMoveFile(false,2)">取消</button> -->
              </div>
          </div>
      </div>
</template>

<script>
import { postGroupMoveGfile, postGroupListGdirs } from "../../../../../common/netWork/group_api";
import GroupMindCrumbs from "./GroupMindCrumbs";

import HttpServerConfig from "../../../../../common/HttpServerConfig";
import timestampToTime from "../../../../../utils/timestampToTime"
import getString from "../../../../../common/lang/language";
import strings from "../../../../../common/lang/strings";
export default {
    name: "GroupMoveFile",
     props: [
        "moveFile",
        "groupDataDetail",
        "moveItem",
        "tab"
    ],
    components: {
      GroupMindCrumbs
    },
    data() {
        return {
          strings,
          showMenu: false,  //是否展示菜单
          showTag: false,  //是否展示创建文件夹框
          nowFileObj: null,  //当前所处文件夹的信息,根目录为空
          // moveFile: false, //是否展示添加文件模态框
          moveData: [], //移动文件夹的数据。里面有所有的子文件夹。
          isElectron: false, //是否是electron环境
          isScroll: true, //是否可以滚动
          searchText: '', //搜索内容
          // groupDataDetailOld: null, //旧的组详情
          mindFileObj: null, //导图列表数据
          page: 1, //当前所在页面
          moveFolderId: "", //当前所在文件夹的id
          gfilesArr: [], //群组文件夹内文件的数组。
          isShowSearchPage: false, //是否展示搜索的页面
          // moveItem: null, //当前移动的数据
          showChangName: false, //是否展示改变文件夹名称模态框
          reportCrispsVisible: false, //举报面板
          reportCrispsContentList: [    //举报理由列表
            this.$Strings.Mind_Storm_Pornographic, //色情低俗
            this.$Strings.Mind_Storm_Advertising_Harassment, //广告骚扰
            this.$Strings.Mind_Storm_Political_Religion, //政治宗教
            this.$Strings.Mind_Storm_Infringement, //侵权（肖像、诽谤、抄袭、冒用）
            this.$Strings.Mind_Storm_Prohibited_Content, //违禁内容
            this.$Strings.Mind_Storm_Spread_Rumors, //造谣传谣
          ],
          reportCrispsContentListNumber: 0, //举报选项
          reportCrispsClickContent: this.$Strings.Mind_Storm_Pornographic, //举报选中的内容
          moveGroupFile: false, //是否展示移动组文件模态框
        }
    },
    methods : {
        getString(i) {
          return getString(i);
        },
        httpImageToPrefix(src) {
          return HttpServerConfig.getMediaSrc(src);
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        backToThePreviousLevel(obj) {
          if (obj.back == true) {
            this.moveFolderId = this.mindFileObj.upperId;
            this.refreshMindList();
          }
        },


        refreshMindList() {   //刷新当前页面数据
          this.pullMindList({ groupId: this.groupDataDetail.id, tab: this.tab, parentId: this.moveFolderId, page: 1 }).then(res=>{

            if (res !=  null) {
              this.mindFileObj = res
              this.page = 1;
              this.gfilesArr = res.gdirs;
            }
            this.$nextTick(() => {
                let scrollDom = document.getElementById("movefilepagebox");
                if (scrollDom != undefined) {
                    scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                    scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                }
            });
          })
        },

        scrollPullList() {
            this.page;
            let obj = { groupId: this.groupDataDetail.id, tab: this.tab, parentId: this.moveFolderId, page: this.page + 1 };
            this.pullMindList(obj).then(res => {

                if (res !=  null && res.gdirs != null && res.gdirs.length > 0) {
                  this.page++;
                  this.gfilesArr = this.gfilesArr.concat(res.gdirs);
                }

            }).finally(res=> {
                this.isScroll = true;
            })
        },


        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("movefilepagebox");
            let child = document.getElementById("movefilepageboxchild");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },

        openMind(item) {
          if (item.type == 0) {
            this.moveFolderId = item.id;
            this.refreshMindList();
            return;
          }
        },

        setGroupMoveFile(obj) {
            return new Promise((resolve, reject) => {
                postGroupMoveGfile(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullMindList(obj) {
            return new Promise((resolve, reject) => {
                postGroupListGdirs(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        hideMoveFile(show,move) {
          if (move == 1) {
            // this.addMindGroupBefore();
            let reqObj = {
              groupId: this.groupDataDetail.id,
              tab: this.tab,
              gfileId: this.moveItem.id,
              dstId:this.moveFolderId
            }
            this.setGroupMoveFile(reqObj).then(res => {
              this.$message.success('移动文件成功');
              this.$emit('hideMoveFile', { show: false, move: 1 });
            })
          } else {
            let obj = {}
            obj.show = show,
            obj.move = move
            this.$emit('hideMoveFile', obj);

          }
        },

    },
    watch: {
      moveFile: {
       handler(val) {
          if (val == true) {
              this.mindFileObj = null; //导图列表数据
              this.page = 1; //当前所在页面
              this.moveFolderId = "", //当前所在文件夹的id
              this.gfilesArr = []; //群组文件夹内文件的数组。

          this.refreshMindList();

          }
        },
        deep: true,
        immediate: true,
      },

    }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: "fileiconfont";
  src: url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
  src: url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2") format("woff2"),
    url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff") format("woff"),
    url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
      format("truetype"),
    url("../../../../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
      format("svg");
}
.fileiconfont {
  font-family: "fileiconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.move-father-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);

.move-child-box {
  width: 520px;
  height: 410px;
  background-color: #fff;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.move-child-box .move-header{
    height: 50px;
}
.move-child-box .move-header {
    padding-top: 10px;
    padding-left: 0px;
}
.move-child-box .move-header .mind-element{
  font-size: 16px;
  cursor: pointer;
}

.move-child-box .move-header .mind-element:nth-last-child(1) i{
  display: none;
}

.move-child-box .move-content {
  overflow: hidden;
    height: 260px;
    max-height: 260px;
    overflow-y: scroll;
}
.move-child-box .move-footer {
    display:flex;
    justify-content: center;

}
.move-child-box .move-footer .button-class {
    margin: 0 20px;
    margin-top: 10px;
    min-width: 60px;
    width: auto;
    padding: 0 10px;
    height: 30px;
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    cursor: pointer;
}
.button-class-ok{
  background: #FD492B;
  color: #fff;
}
.button-class-colse{
  border: 1px solid #F0F2F8;
  color: #999999;
  // background-color: #F0F2F8;
}
.button-class-colse:hover {
  color: #FD492B;
  border: 1px solid #FD492B;
    // background-color: #ff7354;
    // color: #fff;
}
.button-class-ok:hover{
  background: #ff7354;
}

    .mind-file-list-box {
      //  padding-bottom: 150px;
       height: 260px;
       overflow-y: auto;
      .mind-file-list-box-each {
        height: 70px;
        padding: 0 20px;
        padding-top: 20px;
        cursor: pointer;
        .mind-each-left {
          width: 56px;
          height: 56px;
          float: left;
          margin-left: -100%;
        }
        .mind-each-center {
          width: 100%;
          float: left;
          .mind-each-center-title {
            color: #333;
            font-size: 16px;
            height: 30px;
            .mind-each-center-title-content {
              padding-left: 63px;
              display: flex;
              .title-content-name {
                max-width: calc(100% - 138px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .mind-each-center-title-stick {
                background-color:#EEEEEE;
                color: #999999;
                font-size: 12px;
                padding: 0 4px;
                height: 19px;
              }
            }
            .mind-each-center-title-sub-content {
              font-size: 12px;
              margin-top: 4px;
              padding-left: 63px;
              padding-right: 107px;
              color: #999999;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
        .mind-each-right {
          float: right;
          width: 100px;
          height: 56px;
          margin-left: -100px;
          .date-detail {
            color: #333;
            text-align: right;
          }
          .mind-file-menu-icon {
            float: right;
            padding: 2px 10px;
          }
        }
      }

      .each-box-bottom-line {
        height: 2px;
        background-color: #f6f6f6;
        width: calc(100% - 102px);
        margin-right: 20px;
        
        margin-top: 10px;
        float: right;
      }
    }

}

.move-title{
  width: 100%;
  height: 38px;
  position: relative;
  padding: 6px 20px 0 20px;
  background: #efefef;
  border-radius: 5px 5px 0 0;
  
}
.setting-title{
  position: absolute;
  font-size: 18px;
  font-weight: 550;
}
.close-setting{
    position: relative;
    float: right;
}

.close-button-img{
    width:18px;
    cursor: pointer;
    opacity: 0.5;
}
.close-button-img:hover{
  opacity: 0.8;
}
.current-location{
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>