<template>
<!-- 无群组 -->
    <div class="group-not-page" :style="{height: fullHeight - 60 + 'px'}">
        <div class="group-not-page-children">
            <img class="null-group-img" src="../../assets/img/groupImg/groupNull.png" alt="">
            <div class="null-group-text-title">{{ $getStringObj.getString($Strings.Mind_Group_Invite_Friends_And_Colleagues_To_Create_And_Share_Community) }}</div>
            <div class="null-group-botton-box">
                <div @click="nowJoinGroup()" class="group-button group-created-button">
                    <img src="../../assets/img/groupImg/created-group.svg" alt="">
                    <div class="group-created-button-text">{{ $getStringObj.getString($Strings.Mind_Group_Immediately_To_Join) }}</div>
                </div>
                <div @click="createdGroup()" class="group-button group-add-button">
                    <img src="../../assets/img/groupImg/add-group.svg" alt=""> 
                    <div class="group-add-button-text">{{ $getStringObj.getString($Strings.Mind_Group_Immediately_Create) }}</div>
                </div>
            
            </div>
        </div>

        <GroupCreatedNewGroup v-if="showModal" @hiddenAddModal="hiddenAddModal"  @checkGroup="checkGroup" />
        <GroupAddGroupModal :show="showJoinModal" @hidden="hidden" />
    </div>
</template>

<script>
import GroupCreatedNewGroup from "./GroupCreatedNewGroup"
import GroupAddGroupModal from "./GroupAddGroupModal"
import { mapMutations } from "vuex";
import { getJwt } from "@/common/netWork/base";

export default {
    components: {
        GroupCreatedNewGroup,
        GroupAddGroupModal
    },
    props: [
        'fullHeight'
    ],
    data() {
        return {
            showModal: false,
            showJoinModal: false,
        }
    },
    methods: {
        ...mapMutations(["showLoginModel"]),
        createdGroup (val) {
            if (!getJwt()) {
                this.showLoginModel(true);
                return
            }
            this.showModal = true;
        },
        hiddenAddModal(item) {
            if (item.show == false) {
                this.showModal = false;
            }
        },
        checkGroup(val) {
            
        },
        nowJoinGroup() {
            if (!getJwt()) {
                this.showLoginModel(true);
                return
            }
            this.showJoinModal = true
        },
        hidden(obj) {
            if (obj.show == false){
                this.showJoinModal =false
            }
        }
    }

}
</script>

<style lang="less">
    .group-not-page {
        width: 100%;
        
        // max-width: 1200px;
        // margin: 0 auto;
        // .group-left-menu {
        //     float: left;
        //     width: 443px;
        // }
        // .group-right-content {
        //     padding-left: 443px;
        //     width: 100%;
        // }
        .group-not-page-children {
            height: calc(100vh - 80px);
            padding-top: 140px;
            margin: 0 auto;
            width: 757px;
            max-width: 1200px;
            background-color: #fff;
        }

        .null-group-img {
            display: block;
            margin: 0 auto;
            // margin-top: 226px;
        }
        .null-group-text-title {
            margin: 0 auto;
            margin-top: 12px;
            text-align: center;
        }
        .null-group-botton-box {
            margin: 0 auto;
            margin-top: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            .group-button {
                display: flex;
                margin: 0 20px;
                padding: 5px 15px;
                border-radius: 20px;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                background-color: red;
                color: #fff;
                img {
                    width: 20px;
                    height: 20px;
                }
                .group-created-button-text {
                    margin-left: 3px;
                    color: #3D71F2;
                }
                .group-add-button-text {
                    margin-left: 3px;
                    color: #FD492B;
                }
            }
            .group-button:hover {
                cursor: pointer;
            }
            .group-button:active {
                position: relative;
                left: 1px;
                top: 1px;
            }

            .group-add-button {
               background-color: #ffffff;
               border: 2px solid #FD492B;
            }

            .group-created-button {
                background-color: #ffffff;
                border: 2px solid #3D71F2;
            }
        }

    }
</style>