<template>
    <div class="left-box clearfix">
        <div class="left-search">
            <a-button
            class="search-button"
            shape="circle"
            icon="search"
            size="small"
            @click="searchButton()"
            />
            <input
            class="search-box"
            type="text"
            v-model="searchText"
            v-on:keyup.enter="searchButton()"
            />
            <span
            class="cleariconfont clear-search-text-icon"
            v-if="searchText != ''"
            @click="clearSearchText()"
            >&#xe605;</span
            >
        </div>
        <img v-if="this.showUnread" @click="showMessageModalBox" class="left-img left-img-first" src="../../assets/img/groupImg/inform.svg" alt="">
        <img v-else @click="showMessageModalBox" class="left-img left-img-first" src="../../assets/img/groupImg/inform-no.svg" alt="">
        <img class="left-img" @click="createdGroup()" src="../../assets/img/groupImg/addGroupImage.svg" alt="">
        <img @mouseover="moveMenu($event,true)" @mouseout="moveMenu($event,false)" class="left-img"  src="../../assets/img/groupImg/more.svg" alt="">
        <GroupAddGroupMenu :showData="menuList" :pointObj="pointObj" @checkMenuItem="checkMenuItem" />
        <GroupCreatedNewGroup v-if="showModal" @hiddenAddModal="hiddenAddModal"  @checkGroup="checkGroup" />
        <GroupMessageBoard     
            :show="showMessageModal"
            :groupDataDetail="checkGroupData"
            @hidden="hidden"
        />
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import GroupAddGroupMenu from "./GroupAddGroupMenu";
import GroupCreatedNewGroup from "./GroupCreatedNewGroup";
import { postGroupGmessages } from "../../common/netWork/group_api";
import GroupMessageBoard from "./GroupMessageBoard";
import { getJwt } from "@/common/netWork/base";
if ( typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0 ) {
  var {ipcRenderer} = window.require('electron')
// var ipcRenderer = window.ipcRenderer
}
// console.log(this.myGroupData.unread);
export default {
    components: {
        GroupAddGroupMenu,
        GroupCreatedNewGroup,
        GroupMessageBoard
    },
    props: [
        'checkGroupData','setGroupData'
    ],
    data() {
        return {
            menuList: [
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Join_The_Group),
                    image: require('../../assets/img/groupImg/addgroupicon.svg')
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Usinghelp),
                    image: require('../../assets/img/groupImg/helpImage.svg')
                }
            ],
            pointObj: {

            },
            showModal: false, //是否展示模态框
            searchText: '', //搜索的内容
            showMessageModal: false,
            showUnread: false,
            isElectron: false, //是否时electron环境
        }
    },
    created() {
        if(this.setGroupData == null){
            this.showUnread = false;
        }else{
            this.showUnread = this.setGroupData.unread ;
        }
        this.isElectron = this.$tools.isElectron();
    },
    methods: {
        ...mapMutations([
            "setgroupCreatedMenu",
            "showLoginModel"
        ]),
        showMessageModalBox() {
            if (!getJwt()) {
                this.showLoginModel(true);
                return
            }
            this.showMessageModal = true;
        },

        hidden(obj) {
          if (obj.show == false){
              this.showMessageModal = false;
              this.showUnread = false;
          }
        },


        searchButton() {
            this.$emit("searchGroup", {text: this.searchText});
            
        },
        clearSearchText() {
            this.searchText = "";
            this.searchButton()
        },
        moveMenu (e,show,menutype) {  //节点的e对象。是否展示菜单。展示哪种类型的菜单。
            this.menuList = [
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Join_The_Group),
                    image: require('../../assets/img/groupImg/addgroupicon.svg')
                },
                {
                    name: this.$getStringObj.getString(this.$Strings.Mind_Group_Usinghelp),
                    image: require('../../assets/img/groupImg/helpImage.svg')
                }
            ]
            this.pointObj = e.target.getBoundingClientRect();
            if (show) {
                this.setgroupCreatedMenu(true);
            } else {
                this.setgroupCreatedMenu(false);
            }
        },
        checkMenuItem (val) {

            if (val == this.$getStringObj.getString(this.$Strings.Mind_Group_Usinghelp)) {
                let helpLink = "https://www.mindyushu.com/help.html"
                if (this.isElectron) {
                    //打开浏览器
                    ipcRenderer.send("open-url", helpLink);
                } else { 
                    window.open(helpLink);
                }
            }
        },
        createdGroup (val) {
            if (!getJwt()) {
                this.showLoginModel(true);
                return
            }
            this.showModal = true;
        },
        hiddenAddModal(item) {
            if (item.show == false) {
                this.showModal = false;
            }
            
        },
        checkGroup(val) {
            
        },
        pullGroupGmessages(obj) {
            return new Promise((resolve, reject) => {
                postGroupGmessages(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        
    }
}
</script>

<style lang="less">

    @font-face {
    font-family: "cleariconfont";
    src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
    src: url("../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
        format("embedded-opentype"),
        url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2") format("woff2"),
        url("../../assets/font-icon/font_ik9g4nleyx/iconfont.woff") format("woff"),
        url("../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
        format("truetype"),
        url("../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
        format("svg");
    }
    .cleariconfont {
        font-family: "cleariconfont" !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .left-box {
        padding-top: 30px;
        padding-bottom: 20px;
        .left-search {
            width: 260px;
            height: 32px;
            margin-left: 15px;
            position: relative;
            float: left;
            .clear-search-text-icon {
                display: block;
                position: absolute;
                top: 0;
                font-size: 18px;
                left: 228px;
                height: 30px;
                line-height: 32px;
                color: #bbb;
                cursor: pointer;
                &:hover {
                    color: #ff7354;
                }
                &:active {
                    color: #d6301a;
                }
            }

            .search-button {
                position: absolute;
                margin-left: 10px;
                margin-top: 3px;
                border: 0px;
                background: none;
            }

            .ant-btn:hover,
            .ant-btn:focus {
                background: #fd492b;
                color: #fff;
                opacity: 1;
                border: none;
            }
            .search-box {
                width: 100%;
                border-radius: 15px;
                border: transparent;
                background: #F0F2F8;
                margin-right: 10px;
                height: 30px;
                padding-left: 35px;
                padding-right: 30px;
                
            }
            .search-box:focus {
                outline: none;
            }
        }

    }

    .left-img {
        float: left;
        margin-top: 5px;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .left-img-first {
        margin-left: 40px;
    }


</style>