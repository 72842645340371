<template>
  <div class="group-setting-rights-administrator">

    <div v-if="groupDataDetail != null" class="group-rights-admin-list-content">
      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title" style="    height: 32px;font-size: 14px;
    line-height: 32px;">{{ $getStringObj.getString($Strings.Mind_Group_Membership_Limit) }}</div>
          <div class="group-rights-admin-list-box" style="height: 70px;">
            <div class="group-rights-admin-list-box" v-if="gropuMembers != null && gropuMembers.memberCount != null && gropuMembers.memberLimit != null " style="margin-left:20px;height: 70px;margin-top: -6px;">
              <span class="group-rights-admin-list-box-name" >{{ $getStringObj.getString($Strings.Mind_Group_Current_Member_Limit) }}:</span>
               <span class="group-rights-admin-list-box-member-count" > {{ this.gropuMembers.memberCount }}/</span>
              <span class="group-rights-admin-list-box-member-limit" >{{ this.gropuMembers.memberLimit}}</span>
              <span class="group-rights-admin-list-box-name" >{{ $getStringObj.getString($Strings.Mind_Group_Number_Of_People) }}</span>
            </div>
          </div>
      </div>

      <div class="group-rights-admin-list-content-children">
          <div style="    height: 32px;font-size: 14px;
    line-height: 32px;" class="admin-rights-title">{{ $getStringObj.getString($Strings.Mind_Group_Upgrade_Member_Limit) }}</div>
          <div  v-if=" this.gropuGPricesRequest != null && this.gropuGPricesRequest.prices != null" class="group-rights-admin-list-box" style="">
            <template>
              <div style="margin-left:20px"
                class="price-modal-box-children"
                v-for="(each,index) in this.gropuGPricesRequest.prices"
                :class="showIndex==index?'classCheck':''"
                :key="index"
                @click="checkedPrice(each,index)"
              >
                <div class="gray-box" style="position:absolute;z-index:4;margin-left: 49px; margin-top: 15px;font-size:18px">
                  {{each.limit}}{{ $getStringObj.getString($Strings.Mind_Group_Number_Of_People)}}
                </div>
                <div class="modal-icon-box" style="position:absolute;z-index:4;margin-left: 47px; margin-top: 35px;font-size:14px">
                  {{each.mbeansCost}}{{ $getStringObj.getString($Strings.Mind_MBean) }}
                </div>
                <img
                  v-show="showIndex==index"
                  class="group-confirm-selection"
                  style="position:absolute;z-index:5;margin-top: 43px;margin-left: 102px"
                  src="../../../../assets/img/groupImg/checkMBean.png" 
                  alt=""
                />
              </div>
            </template>
          </div>
      </div>
      <div v-if="this.gropuGPricesRequest!=null && this.gropuGPricesRequest.currentMBeans!=null" style="clear:both;margin-left:20px;margin-top:200px">
        <span style="font-size:20px">{{ $getStringObj.getString($Strings.Mind_My_MBean) }}：</span>
        <span style="font-size:24px;font-weight:bold;">{{this.gropuGPricesRequest.currentMBeans}}</span>
         <img style="margin-top: -9px;margin-left: 5px;" src="../../../../assets/img/groupImg/bean.png"/>
      </div>
      <div
            class="confirm-payment-button"
            style=" margin-top: 44px;margin-left:auto; margin-right:auto;text-align:center;width: 416px;height: 40px;line-height: 40px;background: #FD492B;opacity: 1;border-radius: 36px;cursor:pointer"
            @click="confirmPaymentModal"
          >
            <a-button class="confirm-payment-button-a" style="background: #FD492B;color:#ffffff;border:0px;font-size:16px"  @click="confirmPaymentModal">
              {{this.confirmPayment}}
            </a-button>
      </div>
    </div>
    <AlterConfirmPaymentModal 
        :show="showTag" @hiddenAlter="hiddenAlter"  />
  </div>
</template>

<script>
import {postMemberGmemberLimitPrices,postMemberBuyGmemberLimit } from "../../../../common/netWork/member_api";
import AlterConfirmPaymentModal from './AlterConfirmPaymentModal';
import { postGroupMembers,} from "../../../../common/netWork/group_api";
import ChickcheckModal from './ChickcheckModal'
export default {
    components: {
      ChickcheckModal,
      AlterConfirmPaymentModal
    },
    data() {
        return {
          groupDataDetail: null,
          gropuMembers:null,
          gropuGPricesRequest:null,//组成员上限价格列表
          showTag: {showAlter:false},
          showIndex : -1,
          prices: null,
          confirmPayment : this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment),
          pricesList : null,
        }
    },
    created() {
      this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: '' }).then(res => {
        this.gropuMembers = res;
        
      })
      this.pullGPricesRequest({ groupId: this.groupDataDetail.id }).then(res => {
        this.gropuGPricesRequest = res;
        this.pricesList = res.prices;
        
        this.checkedPrice(this.pricesList[0],0);
      })


    },
    mounted() {
      // console.log(this.pricesList);
      // if(this.pricesList != null && this.pricesList != ''){
        
      //   console.log(this.pricesList);
      // }
      
    },
    destroyed() {
    },
    methods: {
        hiddenAlter(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showTag = false;
                    this.confirmPayment = this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment)

                    this.checkedPrice(this.prices,this.showIndex);
                    
                    // this.showIndex = -1;
                    if (obj.confrim == true && obj.contentName==this.$getStringObj.getString(this.$Strings.Mind_Whether_Confirm_Payment_Purchase)) {
                      this.setMaximumNumberOfSuccessfulMembers({priceId:this.prices.id,groupId:this.groupDataDetail.id})
                      .then(res => {
                        // this.prices = null;
                        this.pullGroupMembers({ groupId: this.groupDataDetail.id, lastId: '' }).then(res => {
                          this.gropuMembers = res;
                        })
                        this.pullGPricesRequest({ groupId: this.groupDataDetail.id }).then(res => {
                          this.gropuGPricesRequest = res;
                        })
                      })
                    }
                    if (obj.confrim == true && obj.contentName==this.$getStringObj.getString(this.$Strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean)) {
                      this.bus.$emit('shoppingModel',true);
                      
                    }
                }
            }
        },
        //选中M豆价格
        checkedPrice(each,index) { 
          this.confirmPayment = this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment);
          if(each != null){
            this.showIndex = index
            this.confirmPayment = this.confirmPayment  + each.mbeansCost + "M豆";
            this.prices = each;

          }

          return null
        },

        confirmPaymentModal() {
          let obj;
          if(parseInt(this.prices.mbeansCost) <= parseInt(this.gropuGPricesRequest.currentMBeans)){
            obj = {
              title : this.$getStringObj.getString(this.$Strings.Mind_Tips) ,
              content : this.$getStringObj.getString(this.$Strings.Mind_Whether_Confirm_Payment_Purchase) ,
              showAlter: true,
            }
          }else{
            obj = {
              title : this.$getStringObj.getString(this.$Strings.Mind_Tips) ,
              content : this.$getStringObj.getString(this.$Strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean) ,
              showAlter: true,
            }
          }
          this.showTag = obj

        },

        // checkSwitch(val) {
        //     this.settingFrome[val] = !this.settingFrome[val] 
        //     this.settingFrome.groupId = this.groupDataDetail.id;
        //     this.changeGroupSettings(this.settingFrome).then((res) => {
        //     });
        // }, 
        //获取共享组全部成员信息
       pullGroupMembers(obj) {
            return new Promise((resolve, reject) => {
                postGroupMembers(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        //查看组成员上限价格列表
        pullGPricesRequest(obj){
          return new Promise((resolve, reject) => {
                postMemberGmemberLimitPrices(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
        //成功购买组成员上限
        setMaximumNumberOfSuccessfulMembers(obj){
          return new Promise((resolve, reject) => {
                postMemberBuyGmemberLimit(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        }
    },
    watch: {
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                  this.groupDataDetail = newVal
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
.group-setting-rights-administrator {

  .group-rights-admin-list-content {
    padding-top: 20px;
    .group-rights-admin-list-content-children {
      .admin-rights-title {
        color: #999;
        font-size: 10px;
        padding-left: 20px;
        height: 22px;
        line-height: 22px;
        background-color: #f2f2f2;
      }
      .group-rights-admin-list-box {
        padding-top: 10px;
        padding-bottom: 10px;
        .group-rights-admin-list-box {
          margin-left:10px;
          height:40px;
          margin-top:-10px;
          .group-rights-admin-list-box-name {
            font-size: 20px;
          }
          .group-rights-admin-list-box-member-count{
            font-size:24px;
          }
          .group-rights-admin-list-box-member-limit{
            font-size:24px;
            color: red;
          }
          .each-check-content-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            .each-check-content-button-text {
              margin-right: 8px;
            }        
          }
        }
        .price-modal-box-children{
          border-radius:10px;
          width:140px;height:76px;
          border:1px solid #CCCCCC;
          margin-left:10px;
          margin-top:10px;
          float:left;
          position:relative;z-index:1;
          cursor:pointer;
          .gray-box{
            text-align:center; 
            color:#333333;
          }
          .modal-icon-box{
          margin: 0 auto;
            text-align:center; 
            color:#999999;
          }
          
        }
        .price-modal-box-children:hover {
          border:1px solid #EC705A;   
        }

        .price-modal-box-children:hover .gray-box{
            color:#EC705A;
            }
        .price-modal-box-children:hover .modal-icon-box{
          color: #EC705A;
        }

        .classCheck{
          border:1px solid #EC705A; 
          .modal-icon-box{
            color: #EC705A;
          }
        .gray-box{
            color:#EC705A;
          }
        }
        
      }
    }
  }

}

</style>