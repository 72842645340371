<template>
  <main>
    <div class="group-comment">
      <!-- <div class="file-header-box">
        <div class="gruop-secondary-title">{{ $getStringObj.getString($Strings.Mind_Group_Message_Area) }}</div>
      </div> -->
      <div
        class="storm-user-comment"
        v-if="CommentData.gcomments"
        :style="{ height: fullHeight - 290 + 'px' }"
      >
        <div
          class="storm-user-comment-message"
          v-for="(item, index) in CommentData.gcomments"
          :key="index"
        >
          <div class="storm-user-comment-box">
            <div class="storm-user-header">
              <div v-if="item.isMember" class="storm-user-img-ismember">
                <img :src="httpImageToPrefix(item.userAvatar)" alt="" />
              </div>
              <div v-else class="storm-user-img">
                <img
                  :src="httpImageToPrefix(item.userAvatar)"
                  onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                  alt=""
                />
              </div>
            </div>
            <div class="storm-comment-content">
              <div class="comment-content-header">
                <div class="comment-user-name">
                  <span>{{ item.username }}</span>
                </div>
                <div class="comment-cotnent-detials" v-if="false">
                  <div class="chips-details-single">
                    <img
                      src="../../../../static/img/share/fabulous.svg"
                      alt=""
                    />
                    <span></span>
                  </div>
                  <div class="chips-details-single">
                    <img
                      src="../../../../static/img/userData/news_logo.png"
                      alt=""
                    />
                    <span></span>
                  </div>
                </div>
              </div>

              <div class="comment-content-reply">
                <div class="comment-time">
                  {{ formatTime(item.createTime) }}
                </div>
              </div>
              <div class="comment-content-box">
                <div class="comment-content-text">
                  <span>{{ item.content }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="storm-comment-reply"
            v-if="CommentData.gcomments[index].topComments"
          >
            <div
              class="storm-user-comment-message-reply"
              v-for="(itemReply, replyIndex) in CommentData.gcomments[index]
                .topComments"
              :key="replyIndex"
            >
              <div class="storm-user-header">
                <div v-if="itemReply.isMember" class="storm-user-img-ismember">
                  <img :src="httpImageToPrefix(itemReply.userAvatar)" alt="" />
                </div>
                <div v-else class="storm-user-img">
                  <img
                    :src="httpImageToPrefix(itemReply.userAvatar)"
                    onerror="this.src='/static/img/userData/avatar_yellow_man.png'"
                    alt=""
                  />
                </div>
              </div>
              <div class="storm-comment-content">
                <div class="comment-content-header">
                  <div class="comment-user-name">
                    <span>{{ itemReply.username }}</span>
                  </div>
                  <div class="comment-cotnent-detials">
                    <div class="chips-details-single">
                      <img
                        src="../../../../static/img/share/fabulous.svg"
                        alt=""
                      />
                      <span></span>
                    </div>
                    <div class="chips-details-single">
                      <img
                        src="../../../../static/img/userData/news_logo.png"
                        alt=""
                      />
                      <span></span>
                    </div>
                  </div>
                </div>
                <div class="comment-content-reply">
                  <div class="comment-time">
                    {{ formatTime(itemReply.createTime) }}
                  </div>
                </div>
                <div class="comment-content-box">
                  <div class="comment-content-text">
                    <span>{{ itemReply.content }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发布 -->
      <div class="storm-user-form">
        <a-form :form="commentForm" @submit="commentSubmit">
          <a-form-item>
            <a-input
              v-decorator="[
                'commentContent',
                {
                  rules: [
                    {
                      required: true,
                      message: $getStringObj.getString(
                        $Strings.Storm_Share_Comment_Input_Is_Null_Tips
                      ),
                    },
                  ],
                },
              ]"
              :placeholder="
                $getStringObj.getString($Strings.Mind_Mind_Let_Me_Comment_Too)
              "
              ref="commentInput"
              @change="checkLength($event, 96)"
            />
          </a-form-item>
          <a-form-item class="storm-user-form-button">
            <a-button type="primary" html-type="submit">
              {{ $getStringObj.getString($Strings.Storm_Share_Release) }}
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations } from "vuex";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../../utils/timestampToTime";
import {
  postGroupGcomments,
  postGroupAddGcomment,
} from "../../../common/netWork/group_api";
let groupId = "";
export default {
  props: ["groupId", "fullHeight", "groupDataDetail"],
  data() {
    return {
      CommentData: {
        gcomments: [],
      }, //评论数据
      CommentDataFlag: true, //是否可以请求评论数据
      commentForm: this.$form.createForm(this), //评论表单
      commentContent: [], //评论内容
      commentUserId: "", //回复的用户id
      commentIndex: "", //评论的索引
      commentIsEnd: true, //是否为最后一条评论
      lastId: "", //上一批数据的最后一个的id
      groupDataDetailOld: "", //监听的旧数据
    };
  },
  created() {
    groupId = this.groupId;
    // this.getCommentData();
  },
  mounted() {
    this.loadMoreComment();
  },
  methods: {
    ...mapMutations(["showLoginModel"]),
    httpImageToPrefix(src) {
      return httpImageToPrefix(src);
    },
    //时间处理
    formatTime(time) {
      return dataTimeToFormats.formatTime(time);
    },
    //最多输入48个中文
    checkLength: function (e, maxLength) {
      let dom = e.srcElement;
      let l = 0;
      for (let i = 0; i < dom.value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(dom.value[i])) {
          l += 2;
        } else {
          l++;
        }
        if (l > maxLength) {
          dom.value = dom.value.substr(0, i);
          break;
        }
      }
    },
    hidden() {
      let obj = {
        show: false,
      };
      this.$emit("hiddenFilePage", obj);
    },
    //请求评论数据
    getCommentData() {
      if (this.CommentDataFlag) {
        postGroupGcomments(
          {
            groupId,
            replyTo: this.commentUserId,
            lastId: this.lastId,
          },
          (res) => {
            if (res.gcomments.length > 0) {
              this.CommentData.totalNum = res.totalNum;
              this.CommentData.gcomments = [
                ...this.CommentData.gcomments,
                ...res.gcomments,
              ];
              if (this.lastId == "") {
                this.CommentData = res;
              }
              this.lastId = res.gcomments[res.gcomments.length - 1].id;
              this.CommentDataFlag = true;
            }
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
      this.CommentDataFlag = false;
    },
    //刷新数据
    refCommentData(groupId) {
      postGroupGcomments(
        {
          groupId,
          replyTo: this.commentUserId,
          lastId: this.lastId,
        },
        (res) => {
          // console.log(res);
          if (res.gcomments.length > 0) {
            this.CommentData.totalNum = res.totalNum;
            this.CommentData.gcomments = res.gcomments;
            if (this.lastId == "") {
              this.CommentData = res;
            }
            this.lastId = res.gcomments[res.gcomments.length - 1].id;
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    commentSubmit(e) {
      e.preventDefault();
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.commentForm.validateFields((err, stormComment) => {
        if (!err) {
          postGroupAddGcomment(
            {
              groupId,
              replyTo: this.commentUserId,
              content: stormComment.commentContent,
            },
            (res) => {
              //回复评论id: toCommentId
              if (this.commentUserId != "") {
                this.commentContent[this.commentIndex].topComments.unshift(res);
                this.commentContent[this.commentIndex].commentCount += 1;

                this.commentUserId = "";
              } else {
                this.commentContent.unshift(res);
              }
              this.commentForm.setFieldsValue({
                commentContent: "",
              });
              this.lastId = "";
              this.CommentDataFlag = true;
              this.getCommentData();
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      });
    },
    loadMoreComment() {
      let box = document.querySelector(".storm-user-comment");
      if (box) {
        box.addEventListener(
          "mousewheel",
          (e) => {
            if (this.CommentData.totalNum > 16 && this.CommentDataFlag) {
              setTimeout(() => {
                this.getCommentData();
              }, 1000);
            }
          },
          true
        );
      }
    },
  },
  watch: {
    groupDataDetail: {
      handler(newVal, oldVal) {
        //避免再次点击这个群组的文件，然后群组数据就回到首页了
        if (
          this.groupDataDetailOld != null &&
          this.groupDataDetailOld.id != newVal.id
        ) {
          this.CommentData = { gcomments: [] };
          this.commentContent = []; //评论内容
          this.commentUserId = ""; //回复的用户id
          this.commentIndex = ""; //评论的索引
          this.commentIsEnd = true; //是否为最后一条评论
          this.lastId = ""; //上一批数据的最后一个的id
          groupId = newVal.id;
        }
        this.groupDataDetailOld = newVal;
        this.refCommentData(newVal.id);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.group-comment {
  padding: 20px;
  border-bottom: 1px solid #fff;
  position: relative;

  .file-header-box {
    display: flex;

    .file-header-left {
      flex: 0 0 10px;
      min-height: 72px;
      text-align: center;
      line-height: 72px;
      font-size: 12px;

      .file-header-left-img {
        cursor: pointer;
      }
    }

    .gruop-secondary-title {
      flex: 1;
      width: 100%;
      min-height: 72px;
      line-height: 72px;
      color: #333;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .storm-user-comment {
    overflow: auto;
    width: 100%;

    //评论
    .storm-user-comment-message {
      .storm-user-comment-box {
        display: flex;
        min-height: 110px;
        border-bottom: 1px solid #ececec;
        align-items: center;
        flex-wrap: nowrap;

        .storm-user-header {
          width: 42px;
          height: 80px;
          margin-bottom: 12px;
          cursor: pointer;

          .storm-user-img {
            border: 1px solid #ffffff;
            width: 42px;
            height: 42px;
            border-radius: 50%;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }

          .storm-user-img-ismember {
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-image: url(../../../../static/img/userData/user_message_vip_bg.png);
            background-size: 100%;
            width: 42px;
            height: 52px;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }
        }

        .storm-comment-content {
          // min-width: 322px;
          width: 100%;
          margin-left: 10px;

          .comment-content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .comment-user-name {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: inherit;
              line-height: 17px;
              color: #333333;
              opacity: 1;
              max-height: 20px;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60px;
              }

              .comment-author-logo {
                margin-left: 26px;
                padding: 3px 6px;
                border-radius: 2px;
                background: #ec705a;
                color: #ffffff;
              }
            }

            .comment-cotnent-detials {
              .chips-details-single {
                float: left;
                margin-left: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }

          .comment-content-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;

            .comment-content-text {
              width: 266px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              opacity: 1;
            }
          }

          .comment-content-reply {
            display: flex;
            justify-content: space-between;

            .comment-time {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 17px;
              color: #999999;
              opacity: 1;
            }

            .comment-reply {
              cursor: pointer;
              margin: 0;
              font-size: 12px;
              font-family: Noto Sans SC;
              /*font-weight: bold;*/
              line-height: 17px;
              color: #666666;
              opacity: 1;
            }
          }
        }
      }
    }

    //回复
    .storm-comment-reply {
      border-bottom: 1px solid #ececec;
      padding: 0 0 20px 0;

      .storm-user-comment-message-reply {
        display: flex;
        align-items: center;
        margin-left: 50px;

        .storm-user-header {
          width: 42px;
          height: 80px;
          margin-bottom: 12px;
          cursor: pointer;

          .storm-user-img {
            border: 1px solid #ffffff;
            width: 42px;
            height: 42px;
            border-radius: 50%;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }

          .storm-user-img-ismember {
            border: 1px solid #ffffff;
            border-radius: 50%;
            background-image: url(../../../../static/img/userData/user_message_vip_bg.png);
            background-size: 100%;
            width: 42px;
            height: 52px;

            img {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-top: 12px;
            }
          }
        }

        .storm-comment-content {
          // min-width: 270px;
          width: 100%;
          margin-left: 10px;

          .comment-content-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .comment-user-name {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: inherit;
              line-height: 17px;
              color: #333333;
              opacity: 1;
              max-height: 20px;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                width: 60px;
              }

              .comment-author-logo {
                margin-left: 26px;
                padding: 3px 6px;
                border-radius: 2px;
                background: #ec705a;
                color: #ffffff;
              }
            }

            .comment-cotnent-detials {
              .chips-details-single {
                float: left;
                margin-left: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }

          .comment-content-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 6px;

            .comment-content-text {
              width: 266px;
              font-size: 14px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              opacity: 1;
            }
          }

          .comment-content-reply {
            display: flex;
            justify-content: space-between;

            .comment-time {
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 17px;
              color: #999999;
              opacity: 1;
            }

            .comment-reply {
              cursor: pointer;
              margin: 0;
              font-size: 12px;
              font-family: Noto Sans SC;
              /*font-weight: bold;*/
              line-height: 17px;
              color: #666666;
              opacity: 1;
            }
          }
        }

        //回复
        // .comment-reply-from{
        //   width: 100%;
        //   height: 50px;
        //   border: 1px solid red;
        //   margin: 0 13px;
        // }
      }

      .comment-reply-more {
        display: flex;
        margin-left: 50px;
        font-size: 12px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 17px;
        color: #666666;
        opacity: 1;
        cursor: pointer;
        align-items: center;

        .more-comment-logo {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #666666;
          border-radius: 3px;
          margin-left: 6px;
        }
      }
    }
  }

  .storm-user-comment::-webkit-scrollbar {
    width: 0px;
  }

  .storm-user-comment::-webkit-scrollbar-track {
    background: rgb(239, 239, 239);
    border-radius: 2px;
  }

  .storm-user-comment::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }

  .storm-user-comment::-webkit-scrollbar-thumb:hover {
    background: #b4b4b4;
    cursor: pointer;
  }

  .storm-user-form {
    width: 100%;
    padding: 8px 20px;
    position: absolute;
    bottom: -10.5vh;
    left: 0;
    background: #e1e1e1;
    opacity: 1;
    border-radius: 4px 4px 0px 0px;

    .ant-form {
      display: flex;
      justify-content: space-between;

      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }

      input {
        width: 98%;
        height: 40px;
        border-radius: 4px;
        // border: 1px solid #c8c8c8;
      }

      input:focus {
        border: 1px solid #fd492b;
      }

      .storm-user-form-button {
        width: 67px;

        button {
          width: 67px;
          height: 40px;
          opacity: 1;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
