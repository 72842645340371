<template>
    <div v-show="showPage" :style="{left:pointLeft + 'px',top: pointTop + 'px',opacity: opacity}" ref="menulistBox"  @mouseover="moveMenu(true)" @mouseout="moveMenu(false)" class="menulistBox">
        <div :style="{position:'absolute',left: hiddenLeft + 'px',
        top: hiddenTop + 'px',width:hiddenWidth + 'px',height: hiddenHeight + 'px'}" class="hidden-dom"></div>
        <div v-for="(item,index) in showData" :key="index"
            @click=" checkMenuItem(item,index)" class="menulist-each">
            {{item}}
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
     props: [
        "showData",
        "pointObj",
        "fullHeight"
    ],
    data() {
        return {
            showPage: false,
            pointLeft: 0,   //大盒子相对于整个页面的位置
            pointTop: 0,
            hiddenLeft: 0, //透明盒子相对与父盒子的位置
            hiddenTop: 0,
            hiddenWidth: 0,
            hiddenHeight: 0,
            opacity: 0,
            showAnimal:false,
        }
    },
    methods: {
        ...mapMutations([
            "setgroupMenu"
        ]),
        moveMenu (show) {
            if (show) {
                this.setgroupMenu(true);
            } else {
                this.setgroupMenu(false);
            }
        },
        checkMenuItem (item,index) {

            this.$emit("checkMenuItem",{val: item, show: false});
        }
    },
    watch: {
        "$store.state.groupMenu"(newModelAccout) {    //如果把所有参数都放在vuex里面，会遇到获取dom宽高还有位置延迟的问题，导致计算不准。还有深度监听的频繁触发的问题
            this.showPage = newModelAccout;
        },
        pointObj(val) { 
            //一开始先移入了节点，这时显示菜单的位置不对，获取不到菜单的宽高，所以先让菜单透明，并且由于菜单的一部分刚好出现在鼠标下，
            //这时又触发了鼠标移出事件。这时能获取到菜单的大小了。所以能正常显示菜单的位置了。
            let menudom = this.$refs.menulistBox.getBoundingClientRect();
            if (menudom.width == 0) {   //为了避免在其它位置闪一下。
                this.opacity = 0;
            } else {
                this.opacity = 1;
            }
            this.pointTop = val.y;
            // if(val.y + 110 > this.fullHeight){
            //     this.pointTop = val.y - (val.y + 110 - this.fullHeight);
            // }
            this.pointLeft = val.x - menudom.width;
            this.hiddenLeft = menudom.width;
            this.hiddenTop = 0;
            this.hiddenWidth = val.width;
            this.hiddenHeight = val.width;
        }
    }
}
</script>

<style lang="less" scoped>
    .menulistBox {
        position: fixed;
        left: 200px;
        top: 200px;
        padding: 8px 0;
        margin-bottom: 10px;
        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 0 5px #999;

        .menulist-each {
            max-height: 32px;
            line-height: 32px;
            white-space: nowrap;
            padding: 5px 20px;
            // margin-bottom: 8px;
            font-size: 14px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                background-color: #eee;
            }
        }
        
        .hidden-dom {
            
        }
    }
</style>