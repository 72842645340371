<template>
    <div @click.stop="hidden(false)" v-show="showModal" class="group-add-new-group">
        <div @click.stop="hidden(true)" class="center-writer">
            <div @click.stop="hidden(false)" class="close-modal">
                <a-icon type="close" />
            </div>
            <div class="search-header-input">
                <div class="search-left-search">
                    <a-button
                    class="search-search-button"
                    shape="circle"
                    icon="search"
                    size="small"
                    @click="searchButton()"
                    />
                    <input
                    class="search-search-box"
                    type="text"
                    v-model="searchText"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Enter_The_Group_Number)"
                    v-on:keyup.enter="searchButton()"
                    />
                    <div v-if="searchText != ''" class="cleariconfont search-right-icon" @click="clearSearchText()">&#xe605;</div>
                </div>
            </div>
            <div class="search-group-list">
                <div  class="menu-item">
                    <div class="menu-item-child-box" > 
                        <template v-if="searchGroupData != null && searchGroupData.length > 0" >
                            <div v-for="(item,index) in searchGroupData" @click="jumpDetails(item)" :key="index" style="z-index:1" class="group-message-box clearfix">
                                <div 
                                    class="group-new-img"
                                    :style="{
                                            width: 45 + 'px',
                                            height: 45 + 'px',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            overflow: 'hidden',
                                            backgroundImage: 'url('+ httpImageToPrefix(item.cover) +')',
                                        }"
                                ></div>
                                <div class="group-new-message">
                                    <div class="group-name"> 
                                        <div class="group-name-text">
                                        {{ item.name }}
                                        </div>
                                    </div>
                                    <div class="group-new-time">
                                        {{ timestampToTimeDHM(item.updateTime ) }}
                                    </div>
                                    <div class="group-new-time">
                                        {{ item.subtitle }}
                                    </div>
                                </div>
                                <template v-if="!item.isJoined">
                                    <div v-if="item.mbeansRequired && item.mbeansRequired != 0" @click="applyButton(item)" onClick="event.cancelBubble = true"  class="right-add-button">
                                        <label class="cursor-pointer">{{  $getStringObj.getString($Strings.Mind_Group_Immediately_To_Join) }}</label>
                                        <label class="cursor-pointer" style="margin-left: 4px;">{{ item.mbeansRequired }}</label>
                                         <img src="../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:-2px 0 0 2px;">
                                    </div>
                                    <div v-else-if="item.needVerification" @click="applyButton(item)" onClick="event.cancelBubble = true" class="right-add-button">
                                        {{ $getStringObj.getString($Strings.Mind_Group_Apply_To_Join) }}
                                    </div>
                                    <div v-else @click="applyButton(item)" onClick="event.cancelBubble = true"  class="right-add-button">
                                        {{ $getStringObj.getString($Strings.Mind_Group_Immediately_To_Join) }}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-if="searchGroupData == null || searchGroupData.length == 0">
                            <img class="null-message" src="../../assets/img/groupImg/noMessage/nullSearch.svg"  alt="">
                            <div  class="null-message-text">搜索没有结果哟~</div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
        <!-- <GroupAddGroupModalVerify :show="showInput" @hiddenInput="hiddenInput"  /> -->
       <!-- 申请加入共享组弹框 -->
        <ApplyJoinGroup 
        :tagModalShow="showInput"
        :groupItem="checkGroup"
        @hideModal="hideModal"
        @applyJoinGroup="applyJoinGroup"/>  
    </div>
</template>

<script>
import { postGroupFindGroups, postGroupJoin } from "../../common/netWork/group_api";
import { mapMutations } from "vuex";

import httpImageToPrefix from '../../utils/httpImageToPrefix';
import timestampToTime from "../../utils/timestampToTime";
import GroupAddGroupModalVerify from "./GroupAddGroupModalVerify";
import ApplyJoinGroup from '../myFile/stormChild/ApplyJoinGroup';

export default {
    components: {
        GroupAddGroupModalVerify,
        ApplyJoinGroup
    },
    props: [
        "show"
    ],
    data() {
        return {
            showModal: false,
            searchText: '', //搜索的内容
            searchGroupData: [], //搜索的data
            showInput: false, //展示验证框
            checkGroup: null, //要加入的组
        }
    },
    created() {
    },
    methods: {
        ...mapMutations([
            "setRefreshMyGroupList",
        ]),

        hidden(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hidden", item);
                this.setRefreshMyGroupList();
            }
        },

        searchButton() {
            this.pullGroupFindGroups({
                query: this.searchText
            }).then(res => {
                this.searchGroupData = res.groups
            });
            
        },

        clearSearchText() {
            this.searchText = "";
            this.searchButton()
        },

        applyButton(item) {
            if (!item.isJoined) {
                this.checkGroup = item;
                if (item.mbeansRequired && item.mbeansRequired != 0) {
                    let that = this;
                    this.$confirm({
                        title: this.$getStringObj.getString(this.$Strings.Mind_Tips),
                        content: this.$getStringObj.getString(this.$Strings.Mind_Shooping_Confirm_Payment)+item.mbeansRequired+this.$getStringObj.getString(this.$Strings.Mind_MBean),
                        centered: true,
                        onOk() {
                            that.joinGroup('');
                        },
                        onCancel() {},
                    });  
                }else if (item.needVerification) {
                    this.showInput = true;
                } else {
                    this.joinGroup('');
                }
            }
            
        },
        //跳转详情页
        jumpDetails(item) {
            // console.log(item);
            window.open("/g/"+item.id);
            
        },

        joinGroup(message = null) {
            this.verifyGroupMessage({groupId: this.checkGroup.id,message: message }).then((res) => {
                this.hidden(false);
            }).catch((error) => {
                if (error.code==152) {//M豆数量不足
                    let that = this;
                    this.$confirm({
                    title: this.$getStringObj.getString(this.$Strings.Mind_Tips),
                    content: this.$getStringObj.getString(this.$Strings.Mind_Your_M_Bean_Insufficient_Whether_Go__Purchase_M_Bean),
                    centered: true,
                    onOk() {
                        that.bus.$emit('shoppingModel',true);
                    },
                    onCancel() {},
                    });
                }
            })
        },

        hiddenInput(obj) {
            if (obj.show == false) {
                if (obj.confrim == true) {
                    this.joinGroup(obj.inputText);
                }
                this.showInput = false;
            }
        },
        // 隐藏加入共享组弹框
        hideModal(type){
           this.showInput = false;
        },
        //申请加入
        applyJoinGroup(application){
            this.joinGroup(application);
            this.$message.success(this.$getStringObj.getString(this.$Strings.Mind_Group_Application_Sent_Successfully));
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        verifyGroupMessage(obj) {
            return new Promise((resolve, reject) => {
                postGroupJoin(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullGroupFindGroups(obj) {
            return new Promise((resolve, reject) => {
                postGroupFindGroups(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

    },
    watch: {
        show(newVal) {
            this.showModal = newVal
            this.searchButton();
        }
    }
}
</script>

<style lang="less">
    .group-add-new-group {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 570px;
            min-height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            padding-bottom: 30px;
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }
            .search-header-input {
                width: 490px;
                height: 32px;
                margin: 0 auto;
                margin-top: 30px;
                margin-bottom: 20px;
                .search-left-search {
                    width: 100%;
                    height: 32px;
                    margin-left: 0px;
                    position: relative;

                    .search-right-icon {
                        width: 30px;
                        height: 30px;
                        line-height: 32px;
                        color: #bbb;
                        font-size: 18px;
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        &:hover {
                            color: #ff7354;
                        }
                        &:active {
                            color: #d6301a;
                        }
                    }

                    .search-search-button {
                        position: absolute;
                        margin-left: 10px;
                        margin-top: 3px;
                        border: 0px;
                        background: none;
                    }

                    .ant-btn:hover,
                    .ant-btn:focus {
                        background: #fd492b;
                        color: #fff;
                        opacity: 1;
                        border: none;
                    }

                    .search-search-box {
                        width: 100%;
                        border-radius: 15px;
                        border: transparent;
                        background: #F0F2F8;
                        height: 30px;
                        padding-left: 35px;
                    }

                    .search-search-box:focus {
                        outline: none;
                    }
                }
            }
            .search-group-list {
                .menu-item {
                    .menu-item-title {
                        color: #FD492B;
                        font-size: 14px;
                        padding-left: 20px;
                        padding-bottom: 10px;
                        .show-child-button {
                            margin-left: 16px;
                            color: #333;
                        }
                    }
                    .menu-item-child-box{
                        height: 288px;
                        overflow-y: auto;
                        .group-message-box {
                            width: 520px;
                            margin: 0 auto;
                            padding-top: 11px;
                            padding-bottom: 11px;
                            padding-left: 20px;
                            padding-right: 11px;
                            display: flex;
                            position: relative;
                            align-items: center;
                            cursor: pointer;
                            .group-new-img{
                                display: block;
                                width: 45px;
                                height: 45px;
                                border-radius: 4px;
                            }
                            .group-new-message{
                                margin-left: 10px;
                                .group-name {
                                    display: flex;
                                    align-items: center;
                                    .group-name-text {
                                        max-width: 251px;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        font-size: 14px;
                                        color: #333333;
                                    }
                                    .group-top{
                                        font-size: 10px;
                                        color: #FD492B;
                                        background-color: rgba(0, 0, 0, 0.05);
                                        border-radius: 2px;
                                        padding-top: 3px;
                                        padding-bottom: 3px;
                                        padding-left: 6px;
                                        padding-right: 6px;
                                        margin-left: 16px;
                                    }
                                }
                                .group-new-time {
                                    max-width: 251px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    font-size: 10px;
                                    color:#999999;
                                }

                            }
                            .right-add-button {
                                margin-left: auto;
                                height: 30px;
                                line-height: 26px;
                                font-size: 14px;
                                color: #fff;
                                background-color: #EC705A;
                                border-radius: 15px;
                                padding: 2px 16px;
                                cursor: pointer;
                            }
                            &:hover {
                                background-color: #F1F1F1;
                            }
                        }
                    }

                }

            }

            .null-message {
                display: block;
                margin: 0 auto;
                margin-top: 69px;

            }
            .null-message-text {
                margin-top: 10px;
                color: #999;
                font-size: 14px;
                text-align:center;
            }
            
        }
    }


</style>