<template>
    <div @click.stop="hidden(false)" v-show="showModal" class="group-pull-message">
        <div @click.stop="hidden(true)" class="center-writer">
            <div class="group-setting-administrator-header">
                <!-- <div class="administrator-header-left">
                </div> -->
                <div class="administrator-header-main">
                    {{ $getStringObj.getString($Strings.Mind_Group_Set_The_Message) }}
                </div>
                <div  class="administrator-header-right">
                   <img @click.stop="hidden(false)" class="close-button-img"  src="../../assets/img/popupsImg/colse_new.svg" alt="">
                </div>
            </div>
            <div class="group-gray-title">

            </div>
            <div id="group-message" class="group-message" >
                <div id="group-message-child" class="group-message-child">
                    <template v-if="messageList.length > 0">
                        <div v-for="(item,index) in messageList" :key="index" >
                            <div class="group-message-child-each">
                                <!-- <img src="" alt=""> -->
                                <template v-if="item.cover == null || item.cover == ''">
                                    <img class="group-message-header" src="../../assets/img/groupImg/default-header.png">
                                </template>
                                <template v-else >
                                    <img class="group-message-header" :src="httpImageToPrefix(item.cover)">
                                </template>
                                <div class="group-message-detail">
                                    <div class="group-message-detail-name">
                                        {{ item.title }}
                                    </div>
                                    <template v-if="item.type == 0">
                                        <div class="group-message-detail-reason">
                                            {{ item.message }}
                                        </div>
                                    </template>
                                    <template v-else-if="item.type == 1">
                                        <div class="group-message-detail-sub-title">
                                            <span class="group-message-detail-sub-title-red-name">{{ item.username }}</span>{{ $getStringObj.getString($Strings.Mind_Group_Apply_To_Join) }}{{ item.groupName }}
                                        </div>
                                        <div class="group-message-detail-reason">
                                            {{ item.message }}
                                        </div>
                                    </template>
                                    <template v-else-if="item.type == 2">
                                        <div class="group-message-detail-sub-title">
                                            <span class="group-message-detail-sub-title-red-name">{{ item.username }}</span>{{ $getStringObj.getString($Strings.Mind_Group_Invite_To_Join) }}{{ item.groupName }}
                                        </div>
                                        <div class="group-message-detail-reason">
                                            {{ item.message }}
                                        </div>
                                    </template>
                                </div>
                                <template v-if="item.type == 1 && item.needConfirm">
                                <div class="group-message-right-box">
                                        <div @click="addGroupRequire(item,true)" class="group-message-left-button">{{ $getStringObj.getString($Strings.Mind_Group_Consent) }}</div>
                                        <div @click="addGroupRequire(item,false)" class="group-message-right-button">{{ $getStringObj.getString($Strings.Mind_Group_Turn_Down) }}</div>
                                    </div>
                                </template>
                                <template v-else-if="item.type == 2 && item.needConfirm">
                                    <div class="group-message-right-box">
                                        <div @click="inviteAddGroup(item,true)" class="group-message-left-button">{{ $getStringObj.getString($Strings.Mind_Group_Consent) }}</div>
                                        <div  @click="inviteAddGroup(item,false)" class="group-message-right-button">{{ $getStringObj.getString($Strings.Mind_Group_Turn_Down) }}</div>
                                    </div>
                                </template>

                                <div v-if="index != messageList.length-1" class="message-box-bottom-line"></div>
                            </div>
                            
                        </div>
                    </template>
                    <template v-else >
                        <img class="null-message" src="../../assets/img/groupImg/noMessage/nullMessageInform.svg"  alt="">
                        <div  class="null-message-text">{{ $getStringObj.getString($Strings.Mind_Mind_No_Message_Notifications_Are_Announced_At_This_Time) }}</div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { postGroupGmessages, postGroupConfirmGmessage, postGroupRejectGmessage} from "../../common/netWork/group_api";
import { mapMutations } from "vuex";

import httpImageToPrefix from '../../utils/httpImageToPrefix';
export default {
    components: {

    },
    props: [
        "show",
        "groupDataDetail",
    ],
    data() {
        return {
            showModal: false,
            searchText: '', //搜索的内容
            gropuMembers: null, //所有的组成员
            isAdminType: 'rember', //admin 、 searchAdmin、 rember、 searchRember ,判断是管理员页面，还是管理员搜索，还是成员页面，还是成员搜索
            searchRembersListData: null, //搜索的除管理员以外所有人信息
            isScroll:true,
            messageList: [],
            isScroll: true,
        }
    },
    created() {

    },
    beforeUpdate() {    //每次打开弹窗执行

    },
    methods: {
        ...mapMutations([
            "setgroupAdminMenu",
        ]),

        scrollMoreData() {
            //滚动事件
            let scrollDom = document.getElementById("group-message");
            let child = document.getElementById("group-message-child");
            if( scrollDom == null || child == null) {
                return;
            }
            const scrollTopHeight = scrollDom.scrollTop ; //滚动高度
            const scrollHeight = scrollDom.clientHeight; //父盒子高度
            const childHeight = child.offsetHeight ; //网页可见区域高(包括边线的宽)
            if (
                scrollTopHeight + scrollHeight >= childHeight &&
                this.isScroll
            ) {
                this.isScroll = false;
                this.scrollPullList()
            }
        },


        addGroupRequire(item,bool) { //加入群
            this.emitMessage(item,bool)
        },

        inviteAddGroup(item,bool) { //邀请加入群
            this.emitMessage(item,bool)
        },

        emitMessage(item,bool) {
            if (bool) {
                this.confirmGroupMessage({gmessageId:item.id}).then(res => {
                    this.refreshList()
                })
            } else {
                this.cancelGroupMessage({gmessageId:item.id, reason:''}).then(res => {
                     this.refreshList()
                })
            }
        },



        //隐藏模态框
        hidden(show) {
            this.showModal = show
                if (show == false) {
                let item = {
                    show: false
                }
                this.$emit("hidden", item);
            }
        },

        clearSearchText() {
            this.searchText = "";
            this.searchButton()
        },

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        timestampToTimeDHM(timestamp) {
            return timestampToTime.formatTime(timestamp);
        },

        refreshList() {
            this.pullGroupGmessages({lastId: ''}).then(res => {
                this.messageList = res.gmessages;

                this.$nextTick(() => {
                    let scrollDom = document.getElementById("group-message");
                    if (scrollDom != undefined) {
                        scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
                        scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
                    }
                });
            })
        },

        scrollPullList() {
            this.pullGroupGmessages({lastId: this.messageList[this.messageList.length - 1].id}).then(res => {
                if (res != null && res.gmessages != null) {
                    this.messageList = this.messageList.concat(res.gmessages);
                }
            }).finally(res=> {
                this.isScroll = true;
            })
        },

        // postGroupConfirmGmessage
        confirmGroupMessage(obj) {
            return new Promise((resolve, reject) => {
                postGroupConfirmGmessage(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        cancelGroupMessage(obj) {
            return new Promise((resolve, reject) => {
                postGroupRejectGmessage(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

        pullGroupGmessages(obj) {
            return new Promise((resolve, reject) => {
                postGroupGmessages(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },

    },
    watch: {
        show(newVal) {
            this.showModal = newVal
            if (newVal == true) { //显示模态框请求一次数据
                this.refreshList()
            }
        },
        groupDataDetail: {
            handler(newVal,oldVal) {
                if (newVal != null) {
                }
            },
            immediate: true,
            deep: true
        }
    }
}
</script>

<style lang="less" scoped>
    .group-pull-message {
        position: fixed;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.1);
        z-index: 10;
        .center-writer {
            width: 525px;
            min-height: 400px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 10px;
            .group-setting-administrator-header{
                display: flex;
                padding: 6px 20px;
                background: #efefef;
                .administrator-header-left{
                    flex: 0 0 100px;
                    // text-align: center;
                    font-size: 16px;
                }

                .administrator-header-main{
                    flex: 1;
                    width: 100%;
                    // text-align: center;
                    color: #333;
                    font-size: 18px;
                    font-weight: 550;
                }

                .administrator-header-right{
                    cursor: pointer;
                    flex: 0 0 100px;
                    text-align: right;
                    font-size: 16px;
                    .close-button-img{
                        width:16px;
                        cursor: pointer;
                        opacity: 0.5;
                    }
                    .close-button-img:hover{
                        opacity: 0.8;
                    }
                }

            }
            .close-modal {
                position: absolute;
                right: 0;
                top: 0;
                width: 40px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                font-size: 16px;
                color: #333;
                border-radius: 0 10px 0 0;
            }

            .group-gray-title {
                height: 32px;
                // background-color: #F2F2F2;
            }

            .group-message {
                width: 488px;
                height: 280px;
                // overflow: hidden;
                overflow-y: auto;
                background: #f1f1f1;
                margin: 0 auto;
                &::-webkit-scrollbar {
                    width: 3px;
                    height: 3px;
                }
                &::-webkit-scrollbar-track {
                    background: rgb(239, 239, 239);
                    border-radius: 2px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #bfbfbf;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #bfbfbf;
                }

                .group-message-child {

                    .group-message-child-each {
                        padding:20px 0;
                        display: flex;
                        align-items: center;
                        position:relative;
                        .group-message-header {
                            width: 44px;
                            height: 44px;
                            border-radius: 50%;
                            margin-right: 12px;
                            margin-left: 10px;
                        }
                        .group-message-detail {
                            .group-message-detail-name {
                                color: #333;
                                font-size: 14px;
                                width: 250px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .group-message-detail-sub-title {
                                color: #999;
                                font-size: 14px;
                                width: 250px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                .group-message-detail-sub-title-red-name {
                                    color: #EC705A;
                                }
                            }
                            .group-message-detail-reason {
                                color: #999;
                                font-size: 14px;
                                width: 250px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .group-message-right-box {
                            .group-message-left-button {
                                display: inline-block;
                                // margin-right: 20px;
                                height: 24px;
                                line-height: 24px;
                                padding-left: 11px;
                                padding-right: 11px;
                                border-radius: 2px;
                                color: #fff;
                                background-color: #FD492B;
                                margin-right: 10px;
                                cursor: pointer;
                            }
                            .group-message-left-button:hover{
                                background: #ff7354;
                            }
                            .group-message-right-button {
                                display: inline-block;
                                height: 24px;
                                line-height: 24px;
                                padding-left: 11px;
                                padding-right: 11px;
                                border-radius: 2px;
                                // background-color: #F2F2F2;
                                border: 1px solid #999;
                                color: #999;
                                cursor: pointer;
                            }
                            .group-message-right-button:hover{
                                color: #FD492B;
                                border: 1px solid #FD492B;
                            }
                        }
                        .message-box-bottom-line {
                            position: absolute;
                            bottom: 0;
                            // left: 20px;
                            height: 1px;
                            width: 485px;
                            background-color: #f6f6f6;

                        }
                    }

                }
            }


            .null-message {
                display: block;
                margin: 0 auto;
                width: 110px;
                height: 104px;
                margin-top: 87px;

            }
            .null-message-text {
                margin-top: 10px;
                color: #999;
                font-size: 14px;
                text-align:center;
            }

            // .search-header-input {
            //     width: 574px;
            //     height: 32px;
            //     margin: 0 auto;
            //     margin-top: 20px;
            //     margin-bottom: 0px;
            //     .search-left-search {
            //         width: 100%;
            //         height: 32px;
            //         margin-left: 0px;
            //         position: relative;

            //         .search-right-icon {
            //             width: 30px;
            //             height: 30px;
            //             line-height: 32px;
            //             color: #bbb;
            //             font-size: 18px;
            //             // background-color: red;
            //             position: absolute;
            //             right: 0;
            //             top: 0;
            //             cursor: pointer;
            //             &:hover {
            //                 color: #ff7354;
            //             }
            //             &:active {
            //                 color: #d6301a;
            //             }
            //         }

            //         .search-search-button {
            //             position: absolute;
            //             margin-left: 10px;
            //             margin-top: 3px;
            //             border: 0px;
            //             background: none;
            //         }

            //         .ant-btn:hover,
            //         .ant-btn:focus {
            //             background: #fd492b;
            //             color: #fff;
            //             opacity: 1;
            //             border: none;
            //         }

            //         .search-search-box {
            //             width: 100%;
            //             border-radius: 15px;
            //             border: transparent;
            //             background: #F0F2F8;
            //             height: 30px;
            //             padding-left: 35px;
            //         }

            //         .search-search-box:focus {
            //             outline: none;
            //         }
            //     }
            // }

        }

        // .group-members-big-box {
        //     height: 256px;
        //     margin-top: 20px;
        //     padding-bottom: 0px;
        //     overflow-y: auto;
        //     .group-members-big-box-children {

        //         .members-each {
        //             display: flex;
        //             padding: 9px 20px;
        //             justify-content: space-between;

        //             .members-each-left {
        //                 display: flex;
        //                 align-items: center;
        //                 .members-cover {
        //                     width: 20px;
        //                     height: 20px;
        //                     margin-right: 8px;
        //                 }
        //                 .members-name {
        //                     color: #333;
        //                     margin-left: 8px;
        //                 }
        //             }
        //             .members-each-right {
        //                 color: #999;
        //                 cursor: pointer;
        //             }
        //         }

        //     }
        // }

        // .group-members-footer-menu {
        //     position: absolute;
        //     bottom: 0;
        //     height: 32px;
        //     line-height: 32px;
        //     width: 100%;
        //     display: flex;
        //     justify-content: space-between;
        //     padding-left: 20px;
        //     padding-right: 20px;
        //     .check-rembers-box {

        //     }
        //     .all-click-button-box {
        //         // display: flex;
        //         text-align: right;
        //         span {
        //             margin: 0 15px;
        //             cursor: pointer;
        //             &:hover {
        //                 color: #fd492b;
        //             }
        //         }

        //     }
        // }

    }


</style>