<template>
  <div class="group-setting-rights-administrator">

    <div v-if="groupDataDetail != null && settingFrome != null" class="group-rights-admin-list-content">
      <!-- 访问 -->
      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title">
            {{ $getStringObj.getString($Strings.Mind_Visit) }}
          </div>
          <div class="group-rights-admin-list-box">
            <!-- 是否分享组到风暴平台 -->
            <div class="each-check-content">
              <div class="each-check-content-name">
               <div>{{ $getStringObj.getString($Strings.Share_Group_To_Storm) }} </div> 
                <div class="each-check-content-name-subhead">
                    {{ $getStringObj.getString($Strings.Apply_To_Join_Storm_Platform) }}
                </div>
              </div>
              <div class="each-check-content-button">
                <a-switch @click="checkVisitSwitch('groupToStorm')" :checked="groupToStorm" class="each-check-content-button-text" size="small"/>
              </div>
            </div>
            <!-- 加入时需要支付M豆 -->
            <div class="each-check-content">
              <div class="each-check-content-name">
                <div>{{ $getStringObj.getString($Strings.Pay_To_Goin_Need_Mbeans) }}</div>
                <div class="each-check-content-name-subhead">
                  {{ $getStringObj.getString($Strings.Mbean_Can_Be_Realized) }}
                  <span><a class="content-name-subhead-link" href="" @click.prevent="seeExplain">{{ $getStringObj.getString($Strings.Mind_Details) }}</a></span>
                </div>
              </div>
              <div class="each-check-content-button">
                <div class="m-bean-box">
                  <span class="m-bean-text"> M </span>
                  <img src="../../../../assets/img/user/member/mBean.svg" alt="" width="14px" style="margin:0 8px 0 4px;">
                  <a-select v-model="mBeanNumber" style="width: 102px" @change="mBeanHandleChange" :disabled="!payToGoin">
                    <a-select-option v-for="item of mBeanList" :key="item.value" :value="item.value">
                      {{item.value}}
                    </a-select-option>
                  </a-select>
                </div>
                <a-switch @click="checkVisitSwitch('payToGoin')" :checked="payToGoin" class="each-check-content-button-text" size="small"/>
              </div>
            </div>
             
          </div>
      </div>  

      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title">{{ $getStringObj.getString($Strings.Mind_Group_Establish) }}</div>
          <div class="group-rights-admin-list-box">
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Allows_Mapfolder_Creation) }}</div>
              <div class="each-check-content-button" @click="showCheckModal('allowCreating')">
                <div v-if="this.settingFrome.allowCreating == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowCreating == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowCreating == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Allows_Editing_Of_Maps) }} </div>
              <div @click="showCheckModal('allowEditing')" class="each-check-content-button">
                <div v-if="this.settingFrome.allowEditing == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowEditing == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowEditing == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Allows_Map_Deletion) }}</div>
              <div @click="showCheckModal('allowDeleting')" class="each-check-content-button">
                <div v-if="this.settingFrome.allowDeleting == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowDeleting == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowDeleting == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
            <div class="each-check-content">
              <!-- 允许讨论区发消息 -->
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Allows_Discussion_Boards_To_Post_Messages) }}</div>
              <div @click="showCheckModal('allowComment')" class="each-check-content-button">
                <div v-if="this.settingFrome.allowComment == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowComment == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowComment == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
          </div>
      </div>

      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title">{{ $getStringObj.getString($Strings.Mind_Group_Export) }}</div>
          <div class="group-rights-admin-list-box">
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Allows_You_To_Save_Images_PDF) }}</div>
              <div @click="showCheckModal('allowSaveImagePDF')" class="each-check-content-button">
                <div v-if="this.settingFrome.allowSaveImagePDF == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowSaveImagePDF == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowSaveImagePDF == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">
                <div>
                  {{ $getStringObj.getString($Strings.Mind_Group_Open_Group_Watermark) }}
                </div>
                <div class="each-check-content-name-subhead">
                  {{ $getStringObj.getString($Strings.Mind_Group_When_Turned_On_The_Mind_Map_Will_Be_Printed_With_A_Group_Logo) }}
                </div>
              </div>
              <div class="each-check-content-button">
                <!-- <div class="each-check-content-button-text"> -->
                  <a-switch @click="checkSwitch('groupWaterMark')" :checked="this.settingFrome.groupWaterMark" class="each-check-content-button-text" size="small"  />
                <!-- </div> -->
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">
                <div>
                  {{ $getStringObj.getString($Strings.Mind_Group_Allow_Saving_Maps) }}
                </div>
                <div class="each-check-content-name-subhead">
                  {{ $getStringObj.getString($Strings.Mind_Group_Save_It_To_My_Map) }}
                </div>
              </div>
              <div @click="showCheckModal('allowSave')" class="each-check-content-button">
                <div v-if="this.settingFrome.allowSave == 0" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Holder) }}
                </div>
                <div v-if="this.settingFrome.allowSave == 1" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Only_The_Administrator) }}
                </div>
                <div v-if="this.settingFrome.allowSave == 2" class="each-check-content-button-text">
                  {{ $getStringObj.getString($Strings.Mind_Group_Not_Allow) }}
                </div>
                <img src="../../../../assets/img/groupImg/setting-check.png" alt="">
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Whether_Sharing_To_Storm_Is_Allowed) }}</div>
              <div class="each-check-content-button">
                <a-switch @click="checkSwitch('allowShareToCrisps')" :checked="this.settingFrome.allowShareToCrisps" class="each-check-content-button-text" size="small"  />
              </div>
            </div>
          </div>
      </div>

      <div class="group-rights-admin-list-content-children">
          <div class="admin-rights-title">{{ $getStringObj.getString($Strings.Mind_Group_Join_The_Way) }}</div>
          <div class="group-rights-admin-list-box">
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Whether_To_Invite_New_Members) }}</div>
              <div class="each-check-content-button">
                <a-switch @click="checkSwitch('allowInviting')" :checked="this.settingFrome.allowInviting" class="each-check-content-button-text" size="small"  />
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Whether_To_Allow_Search_Group_Numbers_To_Be_Added) }}</div>
              <div class="each-check-content-button">
                <a-switch @click="checkSwitch('canBeSearched')" :checked="this.settingFrome.canBeSearched" class="each-check-content-button-text" size="small"  />
              </div>
            </div>
            <div class="each-check-content">
              <div class="each-check-content-name">{{ $getStringObj.getString($Strings.Mind_Group_Whether_The_Message_Needs_To_Be_Validated_When_Joining) }}</div>
              <div class="each-check-content-button">
                <a-switch @click="checkSwitch('needVerification')" :checked="this.settingFrome.needVerification" class="each-check-content-button-text" size="small"  />
              </div>
            </div>
          </div>
      </div>

    </div>
    <ChickcheckModal :show="showTag"  @hiddenTap="hiddenTap" />
  </div>
</template>

<script>

import { postGroupGpermissionSettings, postGroupModifyGpermission } from "../../../../common/netWork/group_api";
import httpImageToPrefix from '../../../../utils/httpImageToPrefix';
import ChickcheckModal from './ChickcheckModal'
function ajaxPromise(callback,params,that){
  return new Promise(function (resolve, reject) {
    callback(
      params,
      (res) => {
        resolve(res);
      },
      (error) => {
        reject(error);
        that.$message.error(error.desc);
      }
    );
  });
}
export default {
    components: {
      ChickcheckModal
    },
    data() {
        return {
          groupDataDetail: null,
          groupAdminList: [], //共享组管理员列表
          showTag: {show:false},
          settingFrome: null,
          groupToStorm: true, //是否分享组到风暴
          payToGoin: false, //是否付费加入
          mBeanNumber:0,//使用时需要支付的M豆数量
          mBeanList:[{
            label:1,
            value:1
          },{
            label:2,
            value:2
          },{
            label:3,
            value:3
          },{
            label:5,
            value:5
          },{
            label:10,
            value:10
          },{
            label:15,
            value:15
          },{
            label:20,
            value:20
          }]
        }
    },
    created() {
      this.pullGroupSettings({groupId: this.groupDataDetail.id}).then((res) => {
        this.settingFrome = res;
        this.groupToStorm = res.isPublic;//是否分享组到风暴
        let mbeansRequired = res.mbeansRequired;
        if (mbeansRequired) {
          this.mBeanNumber = mbeansRequired;//付费使用的M豆数量
          this.payToGoin = mbeansRequired == 0 ? false : true;//是否付费加入
        }
        let mbeansOptions = res.mbeansOptions;//付费使用的M豆可选数量列表
        if (!!mbeansOptions && mbeansOptions.length > 0) {
            this.mBeanList = mbeansOptions.map(item => {
                return {
                    label : item,
                    value : item
                }
            })
            this.mBeanNumber = this.mBeanNumber == 0 ? mbeansOptions[0] : this.mBeanNumber//不是付费加入时，M豆数量为列表中的第一个
        }
      })
    },
    mounted() {

    },
    destroyed() {
    },
    methods: {
        hiddenTap(obj) {
            if (obj != null) {
                if (obj.show == false) {    //设置模态框隐藏
                    this.showTag.show = false;
                    let val = this.StringJudgeNumber( obj.checkVal);
                    if (val != null) {
                      this.settingFrome[obj.type] = val

                    }
                }
            }
        },

        StringJudgeNumber(str) {  //根据字符串判断数据类型
          if (str == this.$getStringObj.getString(this.$Strings.Mind_Group_Holder)) {
            return 0
          } else if (str == this.$getStringObj.getString(this.$Strings.Mind_Group_Only_The_Administrator)) {
            return 1
          } else if (str == this.$getStringObj.getString(this.$Strings.Mind_Group_Not_Allow)) {
            return 2
          }
          return null
        },

        showCheckModal(val) {
          let obj;
          obj = {
            menuList:[
              this.$getStringObj.getString(this.$Strings.Mind_Group_Holder),
              this.$getStringObj.getString(this.$Strings.Mind_Group_Only_The_Administrator),
              this.$getStringObj.getString(this.$Strings.Mind_Group_Not_Allow)
            ],
            show: true,
            type: val,
            gId:this.groupDataDetail.id
          }
          this.showTag = obj;
        },

        checkSwitch(val) {
            this.settingFrome[val] = !this.settingFrome[val] 
            this.postChangeGroupSettings()

        },
        checkVisitSwitch(val) {
            this[val] = !this[val] 
            this.postChangeGroupSettings()
        }, 

        httpImageToPrefix(src) {    //拼接图片地址
           return httpImageToPrefix(src)
        },

        changeGroupSettings(obj) {
            return new Promise((resolve, reject) => {
                postGroupModifyGpermission(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },

        pullGroupSettings(obj) {
            return new Promise((resolve, reject) => {
                postGroupGpermissionSettings(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                });
            });
        },
        mBeanHandleChange(value) {
          this.mBeanNumber = value;
          this.postChangeGroupSettings()

        },
        //修改权限
        postChangeGroupSettings(){
          this.settingFrome.groupId = this.groupDataDetail.id;
          this.settingFrome.isPublic = this.groupToStorm;
          this.settingFrome.mbeansRequired =  this.payToGoin ? this.mBeanNumber : 0;
          this.changeGroupSettings(this.settingFrome).then((res) => {});
          // console.log(this.mBeanNumber,this.settingFrome);
        },
        seeExplain(){  
            const h = this.$createElement;
            this.$info({
                title: this.$getStringObj.getString(this.$Strings.Mind_Explain),
                content: h('div', {}, [
                  h('p', this.$getStringObj.getString(this.$Strings.Cloud_Group_Introduction)),
                  h('p', this.$getStringObj.getString(this.$Strings.Cloud_Group_Details)),
                ]),
                centered: true,
                okText:this.$getStringObj.getString(this.$Strings.Global_Ok),
                onOk() {
                },
            });
        }
    },
    watch: {
        "$store.state.groupDataDetail" : {
            handler(newVal,oldVal) {
                if (newVal != null) {
                  this.groupDataDetail = newVal
                }
            },
            immediate: true,
            deep: true
        },
        //付费加入和验证消息只能有一个
        payToGoin(newVal){
            if (newVal) {
                // this.groupToStorm = true;
                this.settingFrome.needVerification = false;
                this.settingFrome.groupId = this.groupDataDetail.id;
                this.postChangeGroupSettings()
            }
        },
        // groupToStorm(newVal){
        //   if (!newVal) {
        //       this.payToGoin = false;
        //       this.postChangeGroupSettings()
        //   }
        // },
        'settingFrome.needVerification'(newVal){
            if (newVal) {
                this.payToGoin = false;
                this.mBeanHandleChange(this.mBeanNumber)
            }
        },
    }
}
</script>

<style lang="less" scoped>
.group-setting-rights-administrator {

  .group-rights-admin-list-content {
    padding-top: 20px;
    .group-rights-admin-list-content-children {
      .admin-rights-title {
        color: #999;
        font-size: 14px;
        padding-left: 20px;
        height: 32px;
        line-height: 32px;
        background-color: #F0F2F8;
      }
      .group-rights-admin-list-box {
        padding-top: 10px;
        padding-bottom: 10px;
        .each-check-content {
          padding-left: 20px;
          padding-right: 20px;
          // height: 35px;
          padding-top: 8px;
          padding-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // justify-content: ;
          .each-check-content-name {
            font-size: 14px;
            color: #333;
            
            .each-check-content-name-subhead{
              padding-top: 4px;
              font-size: 13px;
              color: #999;

              .content-name-subhead-link{
                font-size: 14px;
                color: #EC705A;
                text-decoration: underline;
                margin-left: 10px;
              }
            }
          }
          .each-check-content-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            .each-check-content-button-text {
              margin-right: 8px;
              color: #666666;
            }    
            .m-bean-box{
              display: flex;
              align-items: center;
              margin-right: 16px;
              .m-bean-text{
                font-size: 14px;
                color: #333333;
              }
            }    
          }
        }
      }
    }
  }

}


</style>