<template>

  <div class="group-tag-page" @click.stop="cklickModal(false)" v-show="tagModalShow">
      <div @click.stop="cklickModal(true)" class="group-tag-page-center">
            <div class="group-tag-detail-list">
                <div class="group-tag-detail-name">
                    {{ $getStringObj.getString($Strings.Mind_Group_Verification) }}
                </div>
                <div>
                    <input
                    class="group-tag-detail-input"
                    v-model="groupTagName"
                    @input="inputArea()"
                    v-on:keyup.enter="affirm()"
                    :placeholder="$getStringObj.getString($Strings.Mind_Group_Please_Enter_A_Validation_Message)"
                    >
                    <div class="tag-warning">
                        {{warning}}
                    </div>
                </div>
            </div>
            <div class="group-tap-footer">
                <div @click.stop="cancel()" class="group-tap-footer-button">
                    {{ $getStringObj.getString($Strings.Mind_Group_Cancel) }}
                </div>
                <div @click.stop="affirm()" class="group-tap-footer-button">
                    {{ $getStringObj.getString($Strings.Mind_Group_Confirm) }}
                </div>
            </div>
      </div>
  </div>
</template>

<script>
import { postGroupGetGtag } from "../../common/netWork/group_api";
export default {
     props: [
        "show",
    ],
    data() {
        return {
            tagModalShow: false,
            groupTagName:'',
            confrim: false,
            warning: '',
        }
    },
    methods: {
        cklickModal(val) {
            this.tagModalShow = val;
            if (val == false) { //当隐藏模态框时
                this.hiddenTap({show:false, confrim: this.confrim, inputText: this.trim(this.groupTagName)})
                this.groupTagName = ''
                this.warning = ''
            }
        },
        hiddenTap(obj) {
            this.$emit("hiddenInput", obj);
        },
        affirm() {
            if (this.trim(this.groupTagName) == '') {
                this.warning = this.$getStringObj.getString(this.$Strings.Mind_Group_The_Content_Cannot_Be_Empty)
                return
            }
            this.confrim = true;
            this.pullGroupTag({ name: this.trim(this.groupTagName) }).then(res=> {
                this.cklickModal(false);
                this.confrim = false;
            });
        },
        cancel() {
            this.confrim = false;
            this.cklickModal(false);
        },
        trim(str) { //判断有首尾空格去除空格
            return str.replace(/^\s+|\s+$/g, '');
        },
        inputArea() {   //不允许输入或黏贴超过20个字符
            this.warning =''
            if (this.groupTagName.length > 20){
                this.groupTagName = this.groupTagName.slice(0,20);
            }
        },
        pullGroupTag(obj) {
            //检测版本更新
            return new Promise((resolve, reject) => {
                postGroupGetGtag(
                obj,
                (res) => {
                    resolve(res);
                },
                (error) => {
                    this.$message.error(error.desc);
                    reject(error);
                }
                );
            });
        },
    },
    watch: {
        show: {
            handler(newval, olval) {
                this.tagModalShow = newval
            },
            deep: true,
        },
        groupTagName: {
            handler(newval, olval) {

            },
        },
    }
}
</script>

<style lang="less">
    .group-tag-page {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,.3);
        .group-tag-page-center {
            position: absolute;
            width: 300px;
            min-height: 150px;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border-radius: 4px;
            .group-tag-detail-list {
                width: 230px;
                font-size: 14px;
                line-height: 40px;
                color: #333333;
                margin: 0 auto;
                margin-bottom: 20px;
                .group-tag-detail-name {
                    // margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .group-tag-detail-input {
                    width: 100%;
                    // width: 230px;
                    height: 38px;
                    border: 1px solid #E2E2E2;
                    background-color: #F0F2F8;
                    outline: none;
                    border-radius: 2px;
                    font-size: 12px;
                }
                .tag-warning {
                    // text-align: center;
                    color: #FD492B;
                }
            }
            .group-tap-footer {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                .group-tap-footer-button {
                    cursor: pointer;
                    padding: 0 12px;
                    margin: 0 20px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 12px;
                    font-size: 14px;
                    color: #333;
                    background-color: #F0F2F8;
                }
                .group-tap-footer-button:hover {
                    background-color: #FD492B;
                    color: #fff;
                }
            }
        }
    }
</style>